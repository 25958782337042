import { useEffect, useState } from 'react'

import getYoutubeVideoId from './utils/getYoutubeVideoId'

import './video.scss'

type VideoProps = {
  videoSrc: string
}

function GalleryVideo({ videoSrc }: VideoProps) {
  const [isYoutubeUrl, setIsYoutubeUrl] = useState<boolean>(false)
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>()
  const [youtubeVideoSrc, setYoutubeVideoSrc] = useState('')

  useEffect(() => {
    if (videoSrc?.includes('youtube')) {
      setIsYoutubeUrl(true)

      return
    }

    setIsYoutubeUrl(false)
  }, [setIsYoutubeUrl, videoSrc])

  useEffect(() => {
    if (!isYoutubeUrl) {
      return
    }

    setYoutubeVideoId(getYoutubeVideoId(videoSrc))

    setYoutubeVideoSrc(
      `https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&mute=1&showinfo=0&loop=1&playlist=${youtubeVideoId}`
    )
  }, [isYoutubeUrl, videoSrc, youtubeVideoId])

  return (
    <>
      {isYoutubeUrl ? (
        <iframe
          className="iframe-video"
          src={youtubeVideoSrc}
          allow="autoplay"
          title={`Video ${youtubeVideoId}`}
        />
      ) : (
        <video
          src={videoSrc}
          loop
          autoPlay
          controls={false}
          muted
          playsInline
          className="product-video"
        >
          <source src="movie.mp4" type="video/mp4" />
          <track
            default
            kind="captions"
            srcLang="it"
            src="captions_it.vtt"
            label="italian_captions"
          />
        </video>
      )}
    </>
  )
}

export default GalleryVideo
