import { useRef } from 'react'
import infoIcon from 'src/images/info-icon-pdp.svg'
import { FixedPrice } from './typings'

type ProgressiveDiscountProps = {
  fixedPrices: Array<FixedPrice | null> | null | undefined
  listPrice: number
  addQuantity: number
  unitMultiplier: number | null | undefined
  setAddQuantity?: React.Dispatch<React.SetStateAction<number>>
  handleOpenCloseProgressiveInfo: (a: boolean) => void
}

const ProgressiveDiscountPDP = ({
  fixedPrices,
  listPrice,
  addQuantity,
  unitMultiplier,
  setAddQuantity,
  handleOpenCloseProgressiveInfo,
}: ProgressiveDiscountProps) => {
  const mouseData = useRef({
    isDown: false,
    started: false,
    startX: 0,
    scrollLeft: 0,
  })

  const handleClearMouseData = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    mouseData.current.isDown = false
    mouseData.current.started = false
    e.currentTarget.classList.remove('active')
  }

  const handleMouseEvent = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    switch (e.type) {
      case 'mousedown':
        {
          mouseData.current.isDown = true
          e.currentTarget.classList.add('active')
          mouseData.current.startX = e.pageX - e.currentTarget.offsetLeft
          mouseData.current.scrollLeft = e.currentTarget.scrollLeft
        }
        break
      case 'mouseleave':
      case 'mouseup':
        {
          handleClearMouseData(e)
        }
        break
      case 'mousemove':
        {
          if (!mouseData.current.isDown) return
          if (!mouseData.current.started) mouseData.current.started = true
          e.preventDefault()
          const x = e.pageX - e.currentTarget.offsetLeft
          const walk = (x - mouseData.current.startX) * 3
          e.currentTarget.scrollLeft = mouseData.current.scrollLeft - walk
        }
        break
      default:
        break
    }
  }

  const showIsSelectedPrice = (index: number) => {
    let result: boolean = false

    if (fixedPrices) {
      if (index === fixedPrices?.length - 1) {
        result = Number(fixedPrices[index]?.minQuantity) <= addQuantity
      } else {
        result =
          Number(fixedPrices[index]?.minQuantity) <= addQuantity &&
          addQuantity < Number(fixedPrices[index + 1]?.minQuantity)
      }
    }

    return result
  }

  const handleClickItem = (minQuantity: number) => {
    setAddQuantity?.(minQuantity)
  }

  return (
    <div className="">
      <div className="flex justify-between pr-4 md:px-6">
        <div>
          <div className="px-4 md:px-10 md:pl-0 font-normal">
            Sconto per acquisto multiplo
          </div>
          <div className="px-4 md:px-10 md:pl-0 text-xs md:text-sm">
            Acquista più unità, ottieni un'offerta imbattibile!
          </div>
        </div>
        <div
          onClick={() => handleOpenCloseProgressiveInfo(true)}
          className="cursor-pointer"
          data-modal="info-discount-acquisto-multiplo"
          data-open=""
        >
          <img src={infoIcon} alt="info" />
        </div>
      </div>
      {/* <div
        id="info-discount-acquisto-multiplo"
        className={`modal-lightbox ${openModal ? 'open' : ''}`}
      >
        <div className="lightbox md:!max-w-[calc((100vw/2)-120px)] md:!translate-x-[unset] md:!left-0 md:mr-15 md:ml-auto lg:!max-w-[calc((100vw/3)-80px)] lg:mr-10 xl:!w-[calc((100vw/3)-120px)] xl:mr-15 3xl:!w-[calc((1600px/3)-100px)] 3xl:mr-[calc(((100vw-1600px)/2)+80px)]">
          <div className="mx-auto rounded-lg bg-white">
            <div className="p-6">
              <div className="flex justify-between items-start gap-2 mb-7">
                <div>
                  <p className="text-lg font-normal mb-3">
                    Più compri, più risparmi!
                  </p>
                  <p className="text-xs md:text-sm">
                    Ecco un esempio illustrativo del funzionamento dello sconto
                    per acquisto multiplo:
                  </p>
                </div>
                <button
                  style={{
                    backgroundColor: 'transparent',
                    border: 'hidden',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenCloseModal(false)}
                  className="block"
                  data-close=""
                >
                  <span className="arca-ico-close text-2xl mt-1"></span>
                </button>
              </div>
              <div className="flex justify-between text-base font-normal pb-3 border-b-2 border-[#DDDFE1]">
                <p className="">Prodotto</p>
                <p className="">% di Sconto</p>
              </div>
              <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
                <p className="">X 1</p>
                <p className="">0 %</p>
              </div>
              <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
                <p className="">X 2</p>
                <p className="">5 %</p>
              </div>
              <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
                <p className="">X 3</p>
                <p className="">10 %</p>
              </div>
              <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
                <p className="">X 4 o + articoli</p>
                <p className="">15 %</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-overlay ${
            openModal ? 'block opacity-100' : 'hidden opacity-0'
          }`}
          onClick={() => handleOpenCloseModal(false)}
        ></div>
      </div> */}
      <div className="relative">
        <div
          className="max-w-full overflow-auto cursor-pointer transition-all md:pl-6 carousel-slider no-higlight-scroll only-right only-left"
          onMouseUp={(e) => handleMouseEvent(e)}
          onMouseDown={(e) => handleMouseEvent(e)}
          onMouseLeave={(e) => handleMouseEvent(e)}
          onMouseMove={(e) => handleMouseEvent(e)}
        >
          <div className="pr-6 md:pr-6 w-fit">
            <div className="pl-4 md:pl-0 flex flex-nowrap gap-4 mt-4 pdp-quantity-product-info">
              {fixedPrices?.map((el: FixedPrice | null, index: number) => {
                let discount = 0
                let valueToShow = 0
                let priceEach = 0
                if (el?.value && el?.value !== null && el.minQuantity) {
                  const value = unitMultiplier
                    ? el.value * unitMultiplier
                    : el.value

                  discount =
                    el?.value !== null ? (1 - value / listPrice) * 100 : 0

                  valueToShow = value * el.minQuantity
                  priceEach = valueToShow / el.minQuantity
                }

                return (
                  <div
                    key={`prog-discount-item${index}`}
                    data-qt={el?.minQuantity}
                    className={`relative text-center rounded-[4px] border-2 border-[#DDDFE1] ${
                      showIsSelectedPrice(index) ? 'active-qt' : ''
                    }`}
                    onClick={() => handleClickItem(Number(el?.minQuantity))}
                  >
                    <div className="flex flex-col items-center justify-center min-w-[100px] w-[100px] min-h-[65px] h-[65px] !ml-0">
                      <span className="block text-xs">
                        Compra {el?.minQuantity}
                      </span>
                      <span className="block text-sm font-[400]">
                        {priceEach.toFixed(2)} €/cad
                      </span>
                      <span className="block text-[10px] font-[100] leading-none">
                        Risparmio {(listPrice - priceEach).toFixed(2)} €
                      </span>
                    </div>
                    {index !== 0 && (<span className="absolute block h-5 px-2 bg-[#CE3535] rounded-xl text-white font-[350] -top-2 -right-1 text-[10px] leading-5">
                      -{Math.round(discount)}%
                    </span>)}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-6 mt-1 text-[10px] leading-3">
        *lo sconto crescente verrà applicato in base alla quantità di prodotti
        acquistati
      </div>
    </div>
  )
}

export default ProgressiveDiscountPDP
