import { Price } from '@faststore/ui'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'

type SubscriptionPriceProp = {
  price: number
}

function SubscriptionPricePDP({ price }: SubscriptionPriceProp) {
  const subscriptionPrice = price * 0.95

  return (
    <div className="flex flex-wrap items-center md:gap-2 gap-4 max-w-[345px]">
      <div className="md:min-w-[140px] text-orange-500">
        <span className="arca-ico-autoship mr-1">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
        </span>{' '}
        Ordine periodico:
      </div>
      <div className="flex items-center gap-2">
        <Price
          value={subscriptionPrice}
          formatter={useFormattedPrice}
          testId="list-price"
          data-value={subscriptionPrice}
          variant="listing"
          className="block text-xl font-normal"
        />
        <span className="block text-sm text-orange-500">(-5% EXTRA)</span>
      </div>
    </div>
  )
}

export default SubscriptionPricePDP
