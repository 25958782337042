import { useState } from 'react'

type IngredientsProps = {
  ingredients: string
}

function IngredientsPDP({ ingredients }: IngredientsProps) {
  const [openIngredientsSidebarToggle, setOpenDescriptionSidebarToggle] =
    useState(true)

  const handleOpenSidebar = () => {
    setOpenDescriptionSidebarToggle(!openIngredientsSidebarToggle)
  }

  return (
    <div id="ingredienti" className="">
      <div
        className={`font-normal flex justify-between items-center text-[#14181F] select-none cursor-pointer ${
          openIngredientsSidebarToggle ? 'sidenav-open' : ''
        }`}
        data-collapse-toggle=""
        onClick={handleOpenSidebar}
      >
        <h2 className="text-base md:text-xl">Ingredienti</h2>
        <span className="arca-ico-chevron-down block text-2xl icon-toggler" />
      </div>
      <div className="content-collapse">
        <div className="mt-4 md:mt-6">
          <p
            className="text-black-400 text-sm md:pb-10"
            dangerouslySetInnerHTML={{
              __html: ingredients ?? '',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default IngredientsPDP
