import type { CurrencyCode, UnknownEvent } from '@faststore/sdk'
import { sendAnalyticsEvent, useSession } from '@faststore/sdk'
import { useEffect, useState } from 'react'
import { useWishlistContext } from 'src/contexts/wishlist-context'
import storeConfig from '../../../../store.config'
import type { ExtendedProductDetails } from './typings'

type WishlistButtonProps = {
  product: ExtendedProductDetails
}

const WishlistButtonPDP = ({ product }: WishlistButtonProps) => {
  const {
    person,
    isValidating,
    currency: { code },
  } = useSession()

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)

  const { list, addProductToWishlist, removeProductFromWishlist } =
    useWishlistContext()

  const [isProductOnWishList, setIsProductOnWishList] = useState<boolean>(
    Boolean(list[product.id])
  )

  useEffect(() => {
    setIsProductOnWishList(!!list[product.id])
  }, [list, product.id])

  useEffect(() => {
    if (!isValidating) {
      setIsButtonDisabled(false)
    }
  }, [isValidating, person])

  const handleClick = async () => {
    if (isProductOnWishList) {
      await removeProductFromWishlist(product.id)
    } else {
      const result = await addProductToWishlist({
        productId: product.isVariantOf.productGroupID,
        sku: product.sku,
        title: product.isVariantOf.name,
      })

      if (result) {
        const itemCategory = product.breadcrumbList?.itemListElement
          ?.slice(0, -1)
          .map((x) => x.name)

        const itemListName = itemCategory.join(' ')
        const isKit = product.stockKeepingUnit?.isKit

        sendAnalyticsEvent<UnknownEvent>({
          name: 'add_to_wishlist',
          params: {
            // Adding optional kit parameters
            ...(isKit && {
              bundle_label: product.isVariantOf.name,
              bundle_discount: +(
                product.offers.offers[0]?.listPrice -
                product.offers.offers[0]?.price
              ).toFixed(2),
              bundle_discount_percentage: +(
                1 -
                product.offers.offers[0]?.price /
                  product.offers.offers[0]?.listPrice
              ).toFixed(2),
            }),
            items: [
              {
                index: 0,
                item_list_name: itemListName,
                item_list_id: '',
                item_id: product.isVariantOf.productGroupID,
                item_name: product.isVariantOf.name,
                item_brand: product.brand.name,
                item_variant: product.sku,
                item_category: itemCategory,
                price: product.offers.offers[0]?.price,
                discount: +(
                  product.offers.offers[0]?.listPrice -
                  product.offers.offers[0]?.price
                ).toFixed(2),
                full_price: product.offers.offers[0].listPrice,
                discount_percentage: +(
                  1 -
                  product.offers.offers[0]?.price /
                    product.offers.offers[0]?.listPrice
                ).toFixed(2),
                currency: code as CurrencyCode,
                item_variant_name: product.name,
                product_reference_id: Number(product.gtin),
                quantity: 1,
                coupon: null,
                // Adding optional kit parameters
                ...(isKit && {
                  bundle_label: product.isVariantOf.name,
                  bundle_discount: +(
                    product.offers.offers[0]?.listPrice -
                    product.offers.offers[0]?.price
                  ).toFixed(2),
                  bundle_discount_percentage: +(
                    1 -
                    product.offers.offers[0]?.price /
                      product.offers.offers[0]?.listPrice
                  ).toFixed(2),
                }),
              },
            ],
            value: product.offers.offers[0].price,
            currency: code as CurrencyCode,
          },
        })
      }
    }
  }

  const returnUrl = encodeURIComponent(
    `${storeConfig.storeUrl}${window.location.pathname}`
  )

  return !person?.id ? (
    <a
      className="absolute z-[2] right-5 md:right-0 top-0 rounded-full bg-[#F2F4F5] border border-[#DDDFE1] h-12 w-12 flex items-center justify-center cursor-pointer add-to-favourite"
      href={`${storeConfig.loginUrl}/?returnUrl=${returnUrl}`}
      style={{ textDecoration: 'none' }}
    >
      <span className="arca-ico-hearth-fill text-xl favourite" />
      <span className="arca-ico-hearth text-xl" />
    </a>
  ) : (
    <button
      className={`absolute z-[2] right-5 md:right-0 top-0 rounded-full bg-[#F2F4F5] border border-[#DDDFE1] h-12 w-12 flex items-center justify-center cursor-pointer add-to-favourite${
        isProductOnWishList ? ' isFavourite' : ''
      }`}
      onClick={handleClick}
      disabled={isButtonDisabled}
    >
      <span className="arca-ico-hearth-fill text-xl favourite" />
      <span className="arca-ico-hearth text-xl" />
    </button>
  )
}

export default WishlistButtonPDP
