export function sortWeightAndSize(arr: string[]) {
  if (!arr.length) {
    return []
  }

  const [firstItem] = arr

  const isItLiquid = !!firstItem.indexOf('L')

  if (firstItem.includes('KG') || firstItem.includes('G')) {
    return sortWeightArray(arr)
  }

  if ((firstItem.includes('L') && isItLiquid) || firstItem.includes('ML')) {
    return sortLitersArray(arr)
  }

  return sortSizeArray(arr)
}

function sortWeightArray(arr: string[]) {
  arr.sort((a, b) => {
    const [aWeight, ,] = a.split(',')
    const [bWeight, ,] = b.split(',')

    const aWeightInGrams = aWeight.endsWith('KG')
      ? parseFloat(aWeight.slice(0, -2)) * 1000
      : parseFloat(aWeight.slice(0, -1))

    const bWeightInGrams = bWeight.endsWith('KG')
      ? parseFloat(bWeight.slice(0, -2)) * 1000
      : parseFloat(bWeight.slice(0, -1))

    return aWeightInGrams - bWeightInGrams
  })

  return arr.map((element) => element.split(','))
}

function sortLitersArray(arr: string[]) {
  arr.sort((a, b) => {
    const [aWeight, ,] = a.split(',')
    const [bWeight, ,] = b.split(',')

    const aWeightInGrams = aWeight.endsWith('ML')
      ? parseFloat(aWeight.slice(0, -2)) / 1000
      : parseFloat(aWeight.slice(0, -1))

    const bWeightInGrams = bWeight.endsWith('ML')
      ? parseFloat(bWeight.slice(0, -2)) / 1000
      : parseFloat(bWeight.slice(0, -1))

    return aWeightInGrams - bWeightInGrams
  })

  return arr.map((element) => element.split(','))
}

function sortSizeArray(arr: string[]) {
  const data = arr.map((val) => val.split(','))

  return [...data].sort((a, b) => {
    const sizeOrder = ['XS', 'S', 'M', 'L', 'XL', 'XXL']

    return sizeOrder.indexOf(a[0]) - sizeOrder.indexOf(b[0])
  })
}
