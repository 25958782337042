import type { BreadcrumbProps as UIBreadcrumbProps } from '@faststore/ui'
import { Fragment } from 'react'

type ItemElement = {
  item: string
  name: string
  position: number
}
export interface BreadcrumbProps extends UIBreadcrumbProps {
  breadcrumbList: ItemElement[]
}

interface BaseBreadcrumbProps extends BreadcrumbProps {
  isDesktop?: boolean
}

function BreadcrumbPDP({ breadcrumbList }: BaseBreadcrumbProps) {
  breadcrumbList?.pop()
  const breadCrumbListToShow = breadcrumbList?.slice(0, 4)

  return (
    <>
      <div className="flex gap-1.5 px-4 md:px-10 py-4 md:pt-10 items-center max-w-[1560px] mx-auto">
        <a
          aria-label="Go to homepage"
          href="/"
          className="text-[10px] text-black-400 md:text-sm"
          style={{ textDecoration: 'none' }}
        >
          Homepage
        </a>
        <span className="arca-ico-chevron-right text-[10px] block mt-[3px] text-black" />

        {breadCrumbListToShow?.map(({ item, name }, index: number) => {
          return (
            <Fragment key={`bread-${index}`}>
              <a
                href={item?.replaceAll(' ', '-')?.replace(/\/$/, '')}
                className="text-[10px] text-black-400 md:text-sm"
                style={{ textDecoration: 'none' }}
              >
                {name}
              </a>
              {index !== breadCrumbListToShow.length + 1 &&
                breadCrumbListToShow.length - 1 !== index && (
                  <span className="arca-ico-chevron-right text-[10px] block mt-[3px]" />
                )}
            </Fragment>
          )
        })}
      </div>
    </>
  )
}

export default BreadcrumbPDP
