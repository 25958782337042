import React, { memo, useRef, useState } from 'react'

// import { RenderClient } from 'src/components/RenderClient'
import ImageListPDP from './ImageListPDP'
import type { ImageElementData } from './NewProductDetails'
import VideoThumbsPDP from './VideoThumbsPDP'
import WishlistButtonPDP from './WishlistButtonPDP'
import type { ExtendedProductDetails } from './typings'
import { getImgResources } from './utilities'
// const WishlistButtonPDP = React.lazy(() => import('./WishlistButtonPDP'))

interface ImageGalleryProps {
  product: ExtendedProductDetails
}

function ImagePDP({ product }: ImageGalleryProps) {
  const [selectedImageIdx, setSelectedImageIdx] = useState(0)
  const [isZoomed, setIsZoomed] = useState(false)
  const mouseData = useRef({
    isDown: false,
    started: false,
    startX: 0,
    scrollLeft: 0,
  })

  const videos =
    product?.availableProducts && product?.availableProducts?.videos

  const resources = getImgResources(product.image, videos)

  const handleSelectorClick = (index: number) => {
    setSelectedImageIdx(index)
    setIsZoomed(false)
  }

  const handleMoveImageToTheRight = (index: number) => {
    const indexToSet = resources.length > index ? index : 0

    setSelectedImageIdx(indexToSet)
    setIsZoomed(false)
  }

  const handleMoveImageToTheLeft = (index: number) => {
    const indexToSet = index < 0 ? resources.length - 1 : index

    setSelectedImageIdx(indexToSet)
    setIsZoomed(false)
  }

  const handleClearMouseData = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    mouseData.current.isDown = false
    mouseData.current.started = false
    e.currentTarget.classList.remove('active')
  }

  const handleMouseDownData = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    mouseData.current.isDown = true
    e.currentTarget.classList.add('active')
    mouseData.current.startX = e.pageX - e.currentTarget.offsetLeft
    mouseData.current.scrollLeft = e.currentTarget.scrollLeft
  }

  const handleMouseMoveData = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!mouseData.current.isDown) {
      return
    }

    if (!mouseData.current.started) {
      mouseData.current.started = true
    }

    e.preventDefault()
    const x = e.pageX - e.currentTarget.offsetLeft
    const walk = (x - mouseData.current.startX) * 3

    e.currentTarget.scrollLeft = mouseData.current.scrollLeft - walk
  }

  /* eslint no-fallthrough: "error" */
  const handleMouseEvent = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    switch (e.type) {
      case 'mousedown':
        handleMouseDownData(e)
        break

      case 'mouseleave':

      case 'mouseup':
        handleClearMouseData(e)
        break

      case 'mousemove':
        handleMouseMoveData(e)
        break

      default:
    }
  }

  return (
    <div className="mt-6 pb-4 w-full md:max-w-[calc((100vw/12)*7-40px)] xl:absolute xl:top-0 xl:left-0 xl:max-w-[calc((100vw/12)*5-80px)] xl:ml-10 3xl:w-[calc((1600px/12)*5-60px)]">
      <div className="relative z-0 px-4 md:px-0 md:ml-10 xl:ml-0 xl:px-0">
        <ImageListPDP
          resources={resources}
          selectedImageIdx={selectedImageIdx}
          isZoomed={isZoomed}
          setIsZoomed={setIsZoomed}
          handleMoveImageToTheLeft={handleMoveImageToTheLeft}
          handleMoveImageToTheRight={handleMoveImageToTheRight}
        />
        {resources.length > 1 && (
          <>
            <button
              className="absolute z-[2] left-5 md:left-0 top-[50%] translate-y-[-50%] bg-black-400 rounded-full w-8 h-8 hidden md:flex items-center justify-center cursor-pointer pdp-nav-left"
              onClick={() => handleMoveImageToTheLeft(selectedImageIdx - 1)}
              tabIndex={0}
            >
              <span className="arca-ico-chevron-left block text-white" />
            </button>
            <button
              tabIndex={0}
              className="absolute z-[2] right-5 md:right-0 top-[50%] translate-y-[-50%] bg-black-400 rounded-full w-8 h-8 hidden md:flex items-center justify-center cursor-pointer pdp-nav-right"
              onClick={() => handleMoveImageToTheRight(selectedImageIdx + 1)}
            >
              <span className="arca-ico-chevron-right block text-white" />
            </button>
          </>
        )}

        {/* <Suspense fallback={<div />}>
          <RenderClient> */}
        <WishlistButtonPDP product={product} />
        {/* </RenderClient>
        </Suspense> */}
      </div>

      <div className="mt-4 w-full md:ml-10 md:max-w-[calc(100%-40px)] xl:ml-0 xl:max-w-full">
        <div className="lg:hidden w-full flex flex-wrap gap-2 justify-center items-center">
          {resources?.map((_: ImageElementData, index: number) => {
            return (
              <button
                key={`slide-dot-${index}`}
                tabIndex={0}
                style={{ textAlign: 'unset' }}
                className={`p-[unset] w-[10px] h-[10px] rounded-full bg-[#D9D9D9] cursor-pointer pdp-slides-dots ${
                  selectedImageIdx === index ? 'active' : ''
                }`}
                onClick={() => handleSelectorClick(index)}
              />
            )
          })}
        </div>
        <div className="hidden lg:block">
          <div
            className="h-[66px] overflow-hidden pdp-slides-thumbs-wrapper"
            onMouseUp={(e) => handleMouseEvent(e)}
            onMouseDown={(e) => handleMouseEvent(e)}
            onMouseLeave={(e) => handleMouseEvent(e)}
            onMouseMove={(e) => handleMouseEvent(e)}
          >
            <div className="relative flex gap-2 pdp-slides-thumbs-container">
              {resources?.map((image: ImageElementData, index: number) => {
                return image?.alternateName !== 'video' ? (
                  <button
                    key={`thumb-pdp-${index}`}
                    style={{ textAlign: 'unset' }}
                    className={`p-[unset] bg-transparent h-16 w-16 min-h-[64px] min-w-[64px] border border-[#DDDFE1] relative cursor-pointer pdp-slides-thumbs ${
                      selectedImageIdx === index ? 'active' : ''
                    }`}
                    tabIndex={0}
                    onClick={() => handleSelectorClick(index)}
                  >
                    <img
                      className="object-contain m-auto max-h-full max-w-full"
                      src={image.url}
                      alt={`${product?.isVariantOf?.name} - ${product?.name}`}
                    />
                  </button>
                ) : (
                  <button
                    key={`thumb-pdp-${index}`}
                    tabIndex={0}
                    style={{ textAlign: 'unset' }}
                    className={`p-[unset] bg-transparent h-16 w-16 min-h-[64px] min-w-[64px] border border-[#DDDFE1] relative cursor-pointer pdp-slides-thumbs ${
                      selectedImageIdx === index ? 'active' : ''
                    }`}
                    onClick={() => handleSelectorClick(index)}
                  >
                    <VideoThumbsPDP videoSrc={image.url} />
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ImagePDP)
