import { createRef, memo, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import GalleryVideo from 'src/components/ui/ImageGallery/GalleryVideo'
import { ImageElementData } from './NewProductDetails'
import { getSliderPositionClass } from './utilities'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

type Data = {
  xRatio: number
  yRatio: number
  offset: { left: number; top: number }
  source: { offsetWidth: number; offsetHeight: number }
  sliderTouchPosition: number | null
}

type ImageListProp = {
  resources: ImageElementData[]
  selectedImageIdx: number
  isZoomed: boolean
  setIsZoomed: (a: boolean) => void
  handleMoveImageToTheLeft: (i: number) => void
  handleMoveImageToTheRight: (i: number) => void
}

function ImageListPDP({
  resources,
  selectedImageIdx,
  isZoomed,
  setIsZoomed,
  handleMoveImageToTheLeft,
  handleMoveImageToTheRight,
}: ImageListProp) {
  const zoomedImgRefs = useRef([])
  const imgWrapperRefs = useRef([])
  const sliderWrapperRef = useRef(null)
  const { isMobile } = useBreakpoint()

  zoomedImgRefs.current = resources.map(
    (_, i) => zoomedImgRefs?.current[i] ?? createRef()
  )

  imgWrapperRefs.current = resources.map(
    (_, i) => imgWrapperRefs?.current[i] ?? createRef()
  )

  const data = useRef<Data>({
    xRatio: 0,
    yRatio: 0,
    offset: { left: 0, top: 0 },
    source: { offsetWidth: 0, offsetHeight: 0 },
    sliderTouchPosition: null,
  })

  const offset = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect()
    const scrollLeft = window.scrollX
    const scrollTop = window.scrollY
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  const init = (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.TouchEvent<HTMLElement>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    imageRef: any
  ) => {
    const targetWidth = e.currentTarget?.offsetWidth
    const targetHeight = e.currentTarget?.offsetHeight

    data.current.source.offsetWidth = e.currentTarget.offsetWidth
    data.current.source.offsetHeight = e.currentTarget.offsetHeight

    data.current.xRatio =
      (imageRef.current.offsetWidth - targetWidth) / targetWidth
    data.current.yRatio =
      (imageRef.current.offsetHeight - targetHeight) / targetHeight

    data.current.offset = offset(e.currentTarget)
  }

  const handleMove = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    imageRef: any
  ) => {
    let left = e.pageX - data.current.offset.left
    let top = e.pageY - data.current.offset.top

    top = Math.max(Math.min(top, data.current.source.offsetHeight), 0)
    left = Math.max(Math.min(left, data.current.source.offsetWidth), 0)

    if (imageRef.current !== null) {
      imageRef.current.style.left = `${left * -data.current.xRatio}px`
      imageRef.current.style.top = `${top * -data.current.yRatio}px`
    }
  }

  const handleOnZoomClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    imageRef: any
  ) => {
    if (!isMobile) {
      if (!isZoomed) {
        init(e, imageRef)
        handleMove(e, imageRef)
        setIsZoomed(true)
      } else {
        setIsZoomed(false)
      }
    }
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleTouchMove = (e: React.TouchEvent<HTMLElement>, imageRef: any) => {
    e.preventDefault()
    e.stopPropagation()

    let left = e.touches[0].pageX - data.current.offset.left
    let top = e.touches[0].pageY - data.current.offset.top

    top = Math.max(Math.min(top, data.current.source.offsetHeight), 0)
    left = Math.max(Math.min(left, data.current.source.offsetWidth), 0)

    if (imageRef.current !== null) {
      imageRef.current.style.left = `${left * -data.current.xRatio}px`
      imageRef.current.style.top = `${top * -data.current.yRatio}px`
    }
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleTouchZoom = (e: React.TouchEvent<HTMLElement>, imageRef: any) => {
    e.preventDefault()
    e.stopPropagation()

    init(e, imageRef)
    handleTouchMove(e, imageRef)
    setIsZoomed(true)
  }

  const handleTouchEnd = (e: React.TouchEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setIsZoomed(false)
  }

  const handleSwipeStart = (e: React.TouchEvent<HTMLElement>) => {
    data.current.sliderTouchPosition = e.touches[0].clientX
  }

  const handleSwipeMove = (e: React.TouchEvent<HTMLElement>) => {
    const touchDown = data.current.sliderTouchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      handleMoveImageToTheRight(selectedImageIdx + 1)
    }

    if (diff < -5) {
      handleMoveImageToTheLeft(selectedImageIdx - 1)
    }

    data.current.sliderTouchPosition = null
  }

  useEffect(() => {
    //isMobile is not reliable, sometimes useEffect triggers before plugin download
    if (!window.matchMedia('(max-width: 768px)')?.matches) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      imgWrapperRefs?.current.forEach((el: any, index: number) => {
        el.current.addEventListener(
          'touchstart',
          (e: React.TouchEvent<HTMLElement>) =>
            handleTouchZoom(e, zoomedImgRefs.current[index])
        )

        el.current.addEventListener(
          'touchmove',
          (e: React.TouchEvent<HTMLElement>) =>
            handleTouchMove(e, zoomedImgRefs.current[index])
        )

        el.current.addEventListener(
          'touchend',
          (e: React.TouchEvent<HTMLElement>) => handleTouchEnd(e)
        )

        el.current.addEventListener(
          'mousemove',
          (e: React.MouseEvent<HTMLElement>) =>
            handleMove(e, zoomedImgRefs.current[index])
        )
      })
    }
  }, [])

  return (
    <div className="h-60 md:h-[350px] lg:h-[500px] overflow-hidden relative">
      <div
        className={`pdp-slider`}
        id="image-wrapper"
        ref={sliderWrapperRef}
        onTouchStart={handleSwipeStart}
        onTouchMove={handleSwipeMove}
      >
        {resources?.map((el: ImageElementData, index: number) => {
          return el?.alternateName === 'video' ? (
            <div
              key={`video-${index}`}
              className={`w-full h-full pdp-slide ${getSliderPositionClass(
                resources.length,
                selectedImageIdx,
                index
              )}`}
            >
              <GalleryVideo videoSrc={el.url} />
            </div>
          ) : (
            <div
              key={`img-${index}`}
              className={`w-full h-full pdp-slide ${getSliderPositionClass(
                resources.length,
                selectedImageIdx,
                index
              )}`}
            >
              <Helmet>
                <link rel="preload" as="image" href={el?.url} />
              </Helmet>
              <span
                className="pdp-zoom-wrapper"
                role="button"
                tabIndex={0}
                ref={imgWrapperRefs.current[index]}
                style={{
                  display: 'inline-block',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                }}
                onClick={(e) =>
                  handleOnZoomClick(e, zoomedImgRefs.current[index])
                }
              >
                <img
                  alt="presentation"
                  src={el.url}
                  className="mx-auto object-contain w-[240px] h-[240px] md:w-auto md:h-full"
                />
                <img
                  src={el.url}
                  alt="zoomed"
                  className="zoomImg"
                  ref={zoomedImgRefs.current[index]}
                  style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    opacity: Number(isZoomed),
                    width: 876,
                    height: 1200,
                    border: 'none',
                    maxWidth: 'none',
                    maxHeight: 'none',
                  }}
                />
              </span>
            </div>
          )
        })}
      </div>
      <div
        id="click-here-label"
        className="absolute z-[2] rounded-lg text-white text-xs bg-[rgba(0,0,0,0.32);] px-3 py-1 hidden md:block left-[50%] translate-x-[-50%] bottom-1 whitespace-nowrap click-zoom-feedback"
      >
        Click per ingrandire
      </div>
    </div>
  )
}

export default memo(ImageListPDP)
