function getAnySizeImage(
  currentImageURL: string,
  width: string,
  height: string
): string {
  const idUrl = currentImageURL.match(/\/ids\/(\d+)\//)
  let newUrlproductImages = ''

  if (idUrl) {
    const id = idUrl[1]
    const newId = `${id}-${width}-${height}`

    newUrlproductImages = currentImageURL.replace(id, newId) ?? ''
  }

  const compressedImage = new URL(newUrlproductImages) ?? ''

  compressedImage.searchParams.set('quality', '1')
  compressedImage.searchParams.set('width', width)
  compressedImage.searchParams.set('height', height)

  return compressedImage.href
}

export default getAnySizeImage
