export function StoreTruck() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 15.8333C15.8333 16.7542 15.0867 17.5 14.1667 17.5C13.2467 17.5 12.5 16.7542 12.5 15.8333C12.5 14.9125 13.2467 14.1667 14.1667 14.1667C15.0867 14.1667 15.8333 14.9125 15.8333 15.8333ZM5.83333 14.1667C4.91333 14.1667 4.16667 14.9125 4.16667 15.8333C4.16667 16.7542 4.91333 17.5 5.83333 17.5C6.75333 17.5 7.5 16.7542 7.5 15.8333C7.5 14.9125 6.75333 14.1667 5.83333 14.1667ZM18.125 11.3167V13.75C18.125 14.35 17.8417 14.875 17.4083 15.2167C17.3583 15.2583 17.2667 15.3 17.2084 15.3C17.1084 15.3 17.0167 15.25 16.9917 15.1416C16.6917 13.8666 15.5333 12.9167 14.1667 12.9167C12.7 12.9167 11.4925 13.9992 11.2834 15.4075C11.265 15.5308 11.1667 15.625 11.0417 15.625H8.95833C8.83333 15.625 8.73497 15.5308 8.71663 15.4075C8.5083 13.9992 7.3 12.9167 5.83333 12.9167C4.46667 12.9167 3.30832 13.8666 3.00832 15.1416C2.98332 15.2416 2.8833 15.3083 2.77496 15.3C2.7333 15.3 2.63332 15.25 2.59165 15.2167C2.15832 14.875 1.875 14.35 1.875 13.75V11.7083C1.875 11.57 1.98671 11.4583 2.12504 11.4583H8.33333C8.675 11.4583 8.95833 11.175 8.95833 10.8333C8.95833 10.4917 8.675 10.2083 8.33333 10.2083H2.12504C1.98671 10.2083 1.875 10.0967 1.875 9.95834V4.58333C1.875 3.55249 2.71833 2.70833 3.75 2.70833H10.4167C11.4483 2.70833 12.2917 3.55249 12.2917 4.58333V6.04166H14.1416C16.2583 6.04166 16.6583 7.04165 17.0416 8.00832L17.9083 10.15C17.9667 10.3083 18.0167 10.475 18.05 10.6333C18.0667 10.6833 18.0833 10.7417 18.0833 10.8C18.1083 10.9667 18.125 11.1417 18.125 11.3167ZM16.5834 10.2083L15.875 8.47498C15.525 7.59165 15.4083 7.29166 14.1416 7.29166H12.2917V10.2083H16.5834Z"
        fill="#0F0F13"
      />
    </svg>
  )
}
