import capitalizeFirstLetter from 'src/utils/stringUtils'

import type { AdditionalProperty } from './typings'
import { processAdditionalProperties } from './utilities'

type SpecificationsProps = {
  additionalProperties: AdditionalProperty[]
}

export default function SpecificationsPDP({
  additionalProperties,
}: SpecificationsProps) {
  return (
    <div className="pb-4">
      <div className="mb-3 font-normal">
        <h2 className="text-base md:text-xl">Specifiche prodotto</h2>
      </div>
      <div className="flex flex-col gap-[1px] text-xs table-specifiche-prodotto">
        {Object.keys(additionalProperties).map((el: any, index: number) => {
          return (
            <div
              key={`prod-spec-${index}`}
              className="flex justify-between gap-3"
            >
              <div className="font-normal text-[rgba(20,24,31,0.92)]">
                {processAdditionalProperties(el)}
              </div>
              <div className="font-[325] text-black-400 text-right">
                {capitalizeFirstLetter(
                  additionalProperties[el].value.toLowerCase()
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
