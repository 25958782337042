import { useEffect } from 'react'
import loading from 'src/images/loading.gif'

type LoadingProp = {
  openModal: boolean
  showModal: boolean
  setShowModal: (a: boolean) => void
}

export default function Loading({
  openModal,
  showModal,
  setShowModal,
}: LoadingProp) {
  const LOADING_DURATION = 2000

  useEffect(() => {
    let timer: any
    if (openModal) {
      timer = setTimeout(() => {
        setShowModal(true)
      }, LOADING_DURATION)
    }
    return () => clearTimeout(timer)
  }, [openModal])

  return (
    <div className={`modal-lightbox ${openModal && !showModal ? 'open' : ''}`}>
      <img
        className="left-[50%] top-[50%] -translate-x-[50%] md:-translate-y-[50%] absolute mx-auto w-[80px]"
        src={loading}
      />
      <div className={`modal-overlay block opacity-100`}></div>
    </div>
  )
}
