import type { PricePerUnit } from './typings'

type PricePerUnitProps = {
  lowPrice: number
  pricePerUnit: PricePerUnit | null
  divisor?: string
  currency?: string
  decimals?: number
  decimalsSeparator?: string
}

const PricePerUnitTag = ({
  lowPrice,
  pricePerUnit,
  divisor = '/',
  currency = '€',
  decimals = 2,
  decimalsSeparator = ',',
}: PricePerUnitProps) => {
  const computedPricePerUnit = pricePerUnit?.multiplier
    ? lowPrice / pricePerUnit.multiplier
    : lowPrice

  const computedPricePerUnitToShow = computedPricePerUnit
    .toFixed(decimals)
    .replace('.', decimalsSeparator)

  return (
    <span className="block text-[8px]">
      ({currency}
      {computedPricePerUnitToShow}
      {divisor}
      {pricePerUnit?.unit})
    </span>
  )
}

export default PricePerUnitTag
