function SubscriptionInfo() {
  return (
    <div className="px-0 md:pl-10 xl:pr-10 md:max-w-[calc((100vw/12)*7-40px)] xl:ml-[calc((100vw/12)*5-40px)] xl:mr-[calc((100vw/12)*3.5-40px)] 3xl:w-[calc((1600px/12)*3.5-40px)] 3xl:ml-[calc((1600px/12)*5)] 3xl:mr-[calc((1600px/12)*3.5)] 3xl:px-5">
      <div className="px-4 md:px-0 mt-12 md:mt-4">
        <div className="bg-[#FEF5D9] rounded-lg p-4 md:p-6 border-2 border-[#E27A13] border-dashed flex flex-col gap-1">
          <div className="flex items-center gap-2 mb-1">
            <div className="bg-white rounded-full h-[19px] w-[19px] flex items-center justify-center">
              <span className="arca-ico-autoship block text-lg !leading-[19px]">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </span>
            </div>
            <div className="font-normal text-sm">
              Tutti i vantaggi di Ordine Periodico
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-[19px] w-[19px] flex items-center justify-center">
              <span className="arca-ico-check block text-lg !leading-[19px] text-green-700"></span>
            </div>
            <div className="font-normal text-xs">
              Sconto del 5% EXTRA sui prodotti con Ordine periodico
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-[19px] w-[19px] flex items-center justify-center">
              <span className="arca-ico-check block text-lg !leading-[19px] text-green-700"></span>
            </div>
            <div className="font-normal text-xs">
              Gestisci il tuo Ordine periodico senza costi aggiuntivi
              <small className="block text-[10px] leading-3 font-light">
                Salta, modifica o annulla l'ordine in qualsiasi momento.
              </small>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-[19px] w-[19px] flex items-center justify-center">
              <span className="arca-ico-check block text-lg !leading-[19px] text-green-700"></span>
            </div>
            <div className="font-normal text-xs">
              Non rimanere mai più senza i prodotti preferiti del tuo pet
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionInfo
