import { useEffect, useRef } from 'react'

interface Props {
  // the Dream code of the product
  gtin?: string
  // a function to set the state of the showTrustpilot variable in parent components
  setShowTrustpilot?: (value: boolean) => void
  // the truspilor reviews widgets needs the product name to be passed as a prop
  // note that we are passing the variant name, not the product name here
  productName?: string
  // the TrustPilot widget settings are imported in the parent component and passed as props
  widgetSettings: {
    [key: string]: string
  }

  marginTop?: string

  marginBottom?: string
}

// the TrustPilotWidget component is used in the ProductTitle component (With mini setting) on PDPs (with miniPDP setting) and on the CardMobile component (with standard settings) on PLPs
// it renders a summary of the TrustPilot reviews ( the number of reviews and the average rating as stars )
// it needs to receive the product reference id ( we use the Dream code on TrustPilot) and the widget settings, we need the name only for loading reviews
const TrustPilotWidget = ({
  gtin,
  productName,
  widgetSettings,
  marginTop,
  marginBottom,
}: Props) => {
  const ref = useRef(null)

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // when the refId changes, we need to reload the TrustBox in order to show the reviews for the correct variant
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [gtin])

  return (
    <>
      <div
        style={{
          height: widgetSettings['data-style-height'],
          width: widgetSettings['data-style-width'],
          marginTop,
          marginBottom,
        }}
        className="trustpilot-widget"
        ref={ref}
        data-sku={gtin}
        data-name={productName}
        {...widgetSettings}
      >
        <a
          href="https://it.trustpilot.com/review/arcaplanet.it"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}

export default TrustPilotWidget
