import { useEffect, useState } from 'react'
import getYoutubeVideoId from 'src/components/ui/ImageGallery/utils/getYoutubeVideoId'
import storeConfig from '../../../../store.config'

type VideoThumbsProps = {
  videoSrc: string
}

const { account } = storeConfig

function VideoThumbsPDP({ videoSrc }: VideoThumbsProps) {
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>()
  useEffect(() => {
    if (videoSrc?.includes('youtube')) {
      setYoutubeVideoId(getYoutubeVideoId(videoSrc))
    }
  }, [videoSrc])

  return (
    <>
      {youtubeVideoId ? (
        <img
          src={`https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`}
          alt="Video Thumbs"
          className="mx-auto h-full w-auto object-contain"
        />
      ) : (
        <video preload="metadata" style={{ width: '56px' }}>
          <source src={`${videoSrc}#t=0.5`} />
          <track default kind="captions" />
        </video>
      )}

      <img
        src={`https://${account}.vtexassets.com/arquivos/white-play-icon.png`}
        alt="Play Video Button"
        className="mx-auto h-full w-auto object-contain"
      />
    </>
  )
}

export default VideoThumbsPDP
