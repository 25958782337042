type InfoDiscountProps = {
  openProgressiveInfo: boolean
  handleOpenCloseProgressiveInfo: (a: boolean) => void
}

function InfoDiscount({
  openProgressiveInfo,
  handleOpenCloseProgressiveInfo,
}: InfoDiscountProps) {
  return (
    <div
      id="info-discount-acquisto-multiplo"
      className={`modal-lightbox ${openProgressiveInfo ? 'open' : ''}`}
    >
      <div className="lightbox md:!max-w-[calc((100vw/2)-120px)] md:!translate-x-[unset] md:!left-0 md:mr-15 md:ml-auto lg:!max-w-[calc((100vw/3)-80px)] lg:mr-10 xl:!w-[calc((100vw/3)-120px)] xl:mr-15 3xl:!w-[calc((1600px/3)-100px)] 3xl:mr-[calc(((100vw-1600px)/2)+80px)]">
        <div className="mx-auto h-screen md:h-[unset] rounded-lg bg-white">
          <div className="p-6">
            <div className="flex justify-between items-start gap-2 mb-7">
              <div>
                <p className="text-lg font-normal mb-3">
                  Più compri, più risparmi!
                </p>
                <p className="text-xs md:text-sm">
                  Ecco un esempio illustrativo del funzionamento dello sconto
                  per acquisto multiplo:
                </p>
              </div>
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'hidden',
                  cursor: 'pointer',
                }}
                onClick={() => handleOpenCloseProgressiveInfo(false)}
                className="block"
                data-close=""
              >
                <span className="arca-ico-close text-2xl mt-1"></span>
              </button>
            </div>
            <div className="flex justify-between text-base font-normal pb-3 border-b-2 border-[#DDDFE1]">
              <p className="">Prodotto</p>
              <p className="">% di Sconto</p>
            </div>
            <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
              <p className="">X 1</p>
              <p className="">0 %</p>
            </div>
            <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
              <p className="">X 2</p>
              <p className="">5 %</p>
            </div>
            <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
              <p className="">X 3</p>
              <p className="">10 %</p>
            </div>
            <div className="flex justify-between py-3 text-sm border-b border-[#F2F4F5]">
              <p className="">X 4 o + articoli</p>
              <p className="">15 %</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-overlay ${
          openProgressiveInfo ? 'block opacity-100' : 'hidden opacity-0'
        }`}
        onClick={() => handleOpenCloseProgressiveInfo(false)}
      ></div>
    </div>
  )
}

export default InfoDiscount
