import { Price } from '@faststore/ui'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'

import SubscriptionPricePDP from './SubscriptionPricePDP'
import type { PricePerUnit } from './typings'
import PricePerUnitTag from './PricePerUnitTag'

type PriceProp = {
  priceInfo: {
    verifiedPriceToShow: number
    verifiedListPriceToShow: number
    discount: number
  }
  pricePerUnit?: PricePerUnit | null
  hasSubscription: boolean
}

function PricePDP({ priceInfo, hasSubscription, pricePerUnit }: PriceProp) {
  // const discount = Math.round(priceInfo.discount)
  const isSamePrice =
    priceInfo.verifiedListPriceToShow === priceInfo.verifiedPriceToShow

  return (
    <div className="px-0 md:pl-10 xl:pr-10 md:max-w-[calc((100vw/12)*7-40px)] xl:ml-[calc((100vw/12)*5-40px)] xl:mr-[calc((100vw/12)*3.5-40px)] 3xl:w-[calc((1600px/12)*3.5-40px)] 3xl:ml-[calc((1600px/12)*5)] 3xl:mr-[calc((1600px/12)*3.5)] 3xl:px-5">
      <div className="px-4 md:px-0 py-6 md:pb-0 border-b-4 md:border-b-0 md:border-t-4 border-[#F2F4F5] mb-6 md:mb-0 space-y-2 md:space-y-3">
        <div className="flex flex-wrap items-baseline md:gap-2 gap-4 max-w-[345px]">
          <div className="md:min-w-[110px]">Ordine singolo:</div>
          <div className="flex items-baseline gap-2">
            <div className="leading-none">
              <Price
                value={priceInfo.verifiedPriceToShow}
                formatter={useFormattedPrice}
                testId="price"
                data-value={priceInfo.verifiedPriceToShow}
                variant="spot"
                className="block text-xl font-normal"
              />
              {pricePerUnit && pricePerUnit.unit !== 'none' && (
                <PricePerUnitTag
                  lowPrice={priceInfo.verifiedPriceToShow}
                  pricePerUnit={pricePerUnit}
                />
              )}
            </div>
            {priceInfo.verifiedListPriceToShow && !isSamePrice && (
              <Price
                // style={{ textDecoration: 'line-through' }}
                value={priceInfo.verifiedListPriceToShow}
                formatter={useFormattedPrice}
                testId="list-price"
                data-value={priceInfo.verifiedListPriceToShow}
                variant="listing"
                className="block text-sm relative before:absolute before:content[''] before:top-[50%] before:translate-y-[10%] before:w-full before:h-[1px] before:z-[2] before:bg-black"
              />
            )}
            {/* {discount !== 0 && (
              <span className="block text-sm text-[#CE3535]">
                (-{discount}%)
              </span>
            )} */}
          </div>
        </div>
        {hasSubscription && (
          <SubscriptionPricePDP price={priceInfo.verifiedPriceToShow} />
        )}
      </div>
    </div>
  )
}

export default PricePDP
