import { useEffect } from 'react'
import { AlertIcon } from 'src/components/icons/AlertIcon'

type AddedToCartMessageProp = {
  setShowMessage: (a: boolean) => void
  isUnderPrice: boolean
}
function AddedToCartMessage({
  setShowMessage,
  isUnderPrice,
}: AddedToCartMessageProp) {
  const TOAST_DURATION = 12000

  useEffect(() => {
    const timer = setTimeout(() => {
      isUnderPrice ? '' : setShowMessage(false)
    }, TOAST_DURATION)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div
      className={`${
        isUnderPrice ? 'bg-[#fdefcf]' : 'bg-[#CDFBE7]'
      } rounded-[5px] p-1.5 flex items-center`}
    >
      {isUnderPrice ? (
        <>
          <AlertIcon />
          <div className="pl-1.5 text-xxs leading-none text-[#c25e0d]">
            Attenzione: il valore minimo d'ordine è di{' '}
            <span className="font-semibold">8,99€</span> (spese di consegna
            escluse)
          </div>
        </>
      ) : (
        <>
          <span className="arca-ico-check-circle" />
          <div className="pl-1.5 text-xxs leading-none text-[#08683F]">
            Fatto! Il tuo prodotto è nel carrello. Puoi navigare ancora un po' o
            andare subito al carrello per finalizzare l'acquisto
          </div>
        </>
      )}
    </div>
  )
}

export default AddedToCartMessage
