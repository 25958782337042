import { useEffect, useRef, useState } from 'react'
import { createYoutubeThumbnailElement, getYoutubeThumbnail } from './utilities'

type Props = {
  html: string
}

export const useYoutubeThumbnail = ({ html }: Props) => {
  const [modifiedHTML, setModifiedHTML] = useState(html)

  const iframeData = useRef({ src: '', width: '', height: '' })

  useEffect(() => {
    const parser = new DOMParser()
    const descriptionHTML = parser.parseFromString(html, 'text/html')
    const iframe = descriptionHTML.querySelector('iframe')

    if (iframe) {
      iframeData.current.src = iframe.src
      iframeData.current.width = iframe.width
      iframeData.current.height = iframe.height

      const thumbnail = createYoutubeThumbnailElement(
        iframe.width,
        iframe.height,
        getYoutubeThumbnail(iframe?.src)
      )

      iframe?.parentNode?.replaceChild(thumbnail, iframe)
      const serializer = new XMLSerializer()

      setModifiedHTML(serializer.serializeToString(descriptionHTML))
    }
  }, [])

  useEffect(() => {
    if (modifiedHTML !== html) {
      document
        .getElementById('yt-video')
        ?.addEventListener('click', function () {
          this.outerHTML = `<iframe id="iframe-yt" width="${iframeData?.current?.width}" height="${iframeData?.current?.height}" src="${iframeData?.current?.src}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        })
    }
  }, [modifiedHTML])

  return {
    modifiedHTML,
  }
}
