import { blockOutsideScroll } from './utilities'

type SubscriptionButtonProps = {
  setOpenSubscriptionModal: React.Dispatch<React.SetStateAction<boolean>>
}

function SubscriptionButtonPDP({
  setOpenSubscriptionModal,
}: SubscriptionButtonProps) {
  const handleOpenSubscriptionModal = () => {
    setOpenSubscriptionModal(true)
    blockOutsideScroll(true)
  }

  return (
    <button
      data-modal="set-frequenza-autoship"
      data-tab="imposta-autoship-prodotto"
      data-open=""
      className="btn py-[10px] w-full border border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white transition-all bg-white cursor-pointer"
      onClick={() => handleOpenSubscriptionModal()}
    >
      <div className="flex justify-center items-center gap-3">
        <div className="bg-white rounded-full h-[19px] w-[19px] flex items-center justify-center">
          <span className="arca-ico-autoship block text-lg !leading-[19px]">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </span>
        </div>
        <span className="block text-left">
          IMPOSTA ORA
          <br />
          ordine periodico
        </span>
      </div>
    </button>
  )
}

export default SubscriptionButtonPDP
