import { useState } from 'react'

type DosageProps = {
  dosage: string
}

function DosagePDP({ dosage }: DosageProps) {
  const [openDosageSidebarToggle, setOpenDescriptionSidebarToggle] =
    useState(true)

  const handleOpenSidebar = () => {
    setOpenDescriptionSidebarToggle(!openDosageSidebarToggle)
  }

  return (
    <div id="dosaggio" className="mt-6 mb-6 md:mt-10">
      <div
        className={`font-normal flex justify-between items-center text-[#14181F] select-none cursor-pointer ${
          openDosageSidebarToggle ? 'sidenav-open' : ''
        }`}
        data-collapse-toggle=""
        onClick={handleOpenSidebar}
      >
        <h2 className="text-base md:text-xl">Dosaggio</h2>
        <span className="arca-ico-chevron-down block text-2xl icon-toggler" />
      </div>
      <div className="content-collapse">
        <div className="mt-4 md:mt-6">
          <p
            className="text-black-400 text-sm md:pb-10"
            dangerouslySetInnerHTML={{
              __html: dosage ?? '',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DosagePDP
