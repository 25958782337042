// import { SkuFragmentFragment } from '@generated/graphql'
import { useEffect, useRef, useState } from 'react'
import getAnySizeImage from 'src/utils/getAnySizeImage'
import { sortWeightAndSize } from 'src/utils/sortWeightAndSize'

import type { DefaultSku } from './typings'

type VariantProps = {
  defaultSku: DefaultSku
  variations: any
}

function getSkuInCurrentURL(variations: any[]): string | undefined {
  const lastPartOfURL = window.location.pathname.split('-').slice(-1).join()
  const variation = variations.find((v) => lastPartOfURL.includes(v.sku))

  return variation?.sku
}

function generateURL(sku: string, variations: any[]): string {
  if (typeof window === 'undefined') {
    return ''
  }

  const skuInCurrentURL = getSkuInCurrentURL(variations)

  if (skuInCurrentURL) {
    return window.location.pathname.replace(`${skuInCurrentURL}/p`, `${sku}/p`)
  }

  // Add a sku
  const pathname = window.location.pathname
    .substring(1)
    .split('/p')
    .slice(0, -1)
    .concat(`${sku}/p`)
    .join('-')

  return window.location.pathname.replace(
    window.location.pathname,
    `/${pathname}`
  )
}

const VariantPDP = ({ defaultSku, variations }: VariantProps) => {
  const [, setSelectedSku] = useState<string>(defaultSku.sku ?? '')
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const [sortedSKUArray, setSortedSKUArray] = useState<string[][]>([])
  const dropdownOpenStatus = useRef<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const showVariantsDropdown = () => {
    if (defaultSku.hasMoreThanOneVariant) {
      setIsDropdownOpened(!isDropdownOpened)
      dropdownOpenStatus.current = !isDropdownOpened
    }
  }

  useEffect(() => {
    const newSKUarray: string[] = []

    variations.forEach((option: any) => {
      const newArray = [
        option.name,
        option.sku,
        option?.offers?.offers[0]?.availability,
      ]

      if (option?.image) newArray.push(option?.image[0].url)

      newSKUarray.push(`${newArray}`)
    })

    const sortedArray = [...newSKUarray].sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true })
    )

    setSortedSKUArray(sortWeightAndSize(sortedArray))
  }, [variations])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as HTMLElement) &&
        dropdownOpenStatus.current &&
        defaultSku.hasMoreThanOneVariant
      ) {
        setIsDropdownOpened(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <div
      ref={ref}
      className={`${
        !defaultSku.availability ? 'px-4 md:px-10 md:pl-0' : 'px-4 md:px-6'
      }`}
    >
      <div className="mb-2 font-normal">Seleziona la variante</div>
      <div
        className="flex flex-wrap static-label text-sm"
        style={{ paddingBottom: `${!defaultSku.availability ? '30px' : ''}` }}
      >
        <div className="mx-auto w-300 relative w-[100%]">
          <div
            onClick={showVariantsDropdown}
            className={`!border-black !border-2 w-[100%] h-[100%] flex px-5 bg-[#ffffff] rounded-lg font-normal py-2 ${
              defaultSku.hasMoreThanOneVariant
                ? 'cursor-pointer select-arrow'
                : ''
            }`}
          >
            <img
              className={`pr-2 ${
                !defaultSku.availability ? 'opacity-40 line-through' : ''
              }`}
              width="30px"
              height="20px"
              src={getAnySizeImage(defaultSku.url, '150', '150')}
              alt="url-default"
            />
            <span
              className={`${
                !defaultSku.availability ? 'opacity-40 line-through' : ''
              } w-10/12`}
            >
              {defaultSku.name}
            </span>
          </div>
          <div
            style={{
              display: isDropdownOpened ? 'block' : 'none',
            }}
            className="absolute left-0 w-[100%] shadow-[0_0_10px_5px_rgba(0,0,0,0.07)] bg-white rounded-md max-h-[260px] h-auto overflow-y-auto -z--1"
          >
            {sortedSKUArray.map((option: string[], idx: number) => {
              return (
                <span
                  className="w-full overflow-y-hidden overflow-x-hidden"
                  key={idx}
                >
                  <a
                    key={`url-${idx}`}
                    href={generateURL(option[1], variations)}
                    className="no-underline cursor-pointer px-2 py-1 font-normal whitespace-nowrap h-1.2 flex hover:bg-blue-800 hover:text-white text-[0.85rem]"
                  >
                    <img
                      className={`${
                        option[2] !== 'https://schema.org/InStock'
                          ? 'opacity-40 line-through'
                          : ''
                      }`}
                      width="20px"
                      height="20px"
                      src={getAnySizeImage(option[3], '150', '150')}
                      alt={`url-${idx}`}
                    />
                    <div
                      className={`pl-2 ${
                        option[2] !== 'https://schema.org/InStock'
                          ? 'opacity-40 line-through'
                          : ''
                      }
                        `}
                      onClick={() => {
                        setSelectedSku(option[1])
                        setIsDropdownOpened(!isDropdownOpened)
                      }}
                    >
                      {option[0]}
                    </div>
                  </a>
                </span>
              )
            })}
          </div>
        </div>
        {!defaultSku.availability && variations.length > 1 && (
          <div className="text-xxs leading-none pt-1">
            *Scopri le alternative: la variante selezionata non è al momento
            disponibile.
          </div>
        )}
      </div>
    </div>
  )
}

export default VariantPDP
