export const StoreEmailInfoToastIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00927734" width="28" height="28" rx="14" fill="white" />
      <path
        d="M19.3336 14.7293V18.0093C19.3336 19.3426 18.6669 20.0093 17.3336 20.0093H15.5535C15.4429 20.0093 15.3536 19.92 15.3536 19.8093V17.7573C15.3536 17.0926 14.9035 16.4726 14.2482 16.3619C13.4155 16.2219 12.6869 16.8679 12.6869 17.6759V19.8093C12.6869 19.92 12.5975 20.0093 12.4869 20.0093H10.6669C9.33356 20.0093 8.66689 19.3426 8.66689 18.0093V14.7293C9.06022 14.9159 9.49357 15.0093 9.93357 15.0093C10.6936 15.0093 11.4202 14.7426 11.9802 14.3026C12.5402 14.7426 13.2602 15.0093 14.0002 15.0093C14.7469 15.0093 15.4669 14.7426 16.0269 14.3026C16.5869 14.7426 17.3135 15.0093 18.0735 15.0093C18.5069 15.0093 18.9402 14.9159 19.3336 14.7293ZM11.0002 8.00928C9.33356 8.00928 9.0782 8.55594 8.80353 9.36794L8.11025 11.4166C7.81825 12.2793 8.11357 13.2773 8.90957 13.7373C9.2089 13.9106 9.55892 14.0093 9.93292 14.0093C10.8196 14.0093 11.6922 13.4559 11.9695 12.6759C12.2402 13.4559 13.1129 14.0093 13.9996 14.0093C14.8862 14.0093 15.7589 13.4559 16.0295 12.6759C16.3069 13.4559 17.1802 14.0093 18.0662 14.0093C18.4409 14.0093 18.7902 13.9106 19.0896 13.7373C19.8856 13.2773 20.1809 12.2793 19.8889 11.4166L19.1955 9.36794C18.9222 8.55594 18.6669 8.00928 17.0002 8.00928H11.0002Z"
        fill="#143669"
      />
    </svg>
  )
}
