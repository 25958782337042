import type { ProductDetailsFragment_ProductFragment } from '@generated/graphql'
import { memo } from 'react'
import Link from 'src/components/ui/Link'
import TrustPilotWidget from 'src/components/ui/TrustPilotWidget/TrustPilotWidget'
import widgetSettings from 'src/components/ui/TrustPilotWidget/widgetSettings'

interface TitlePDPProp {
  product: ProductDetailsFragment_ProductFragment
}

function TitlePDP({ product }: TitlePDPProp) {
  return (
    <>
      <Link
        style={{ display: 'block' }}
        className="underline text-black-400 mt-2 text-sm"
        as="a"
        href={`/brand/${product.brand?.name
          .toLowerCase()
          .replace(/[' ']/g, '-')
          .replace(/['&']/g, '-')
          .replace(/[' '/\\#,+()$~%.'":*?<>{}]/g, '')}`}
      >
        {product.brand?.name}
      </Link>
      <h1 className="text-2xl mt-2 mb-2">{product.isVariantOf.name}</h1>

      <div className="flex items-center my-2 md:mt-0 md:mb-5 gap-2">
        <TrustPilotWidget
          gtin={product.gtin}
          widgetSettings={widgetSettings.miniNewPDP}
        />
        {/* <div className="flex justify-between gap-2 w-full">
          <div className="text-[10px]"></div>
          <div className="text-[10px] font-normal hidden lg:block">
            <a href="#recensioni" className="">
              Leggi tutte le recensioni
            </a>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default memo(TitlePDP)
